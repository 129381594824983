import * as XLSX from 'xlsx'
import { timeUtcToHHMMSS } from '../../utils'
import type { ExcelData, SpeedExcelRow, WaypointsTimeExcelRow } from '../../types/excel'
import moment from 'moment'

export const waypointsTimeExcelData = (info: WaypointsResult[] , timezone : string) => {
    var waypointExcel: any[] = []

    let wpUnchecked: any = []
    let wpChecked: any = []
    let OutTimeWpChecked: any = []
    let OutTimeWpUnchecked: any = []

    for (const result of info) {
        if (result.data.waypoints.length > 0) {
            const row: any = {
                Participant: result.number,
            };

            for (let areaPoint of result.areaPoints) {
                row[areaPoint.name] =  areaPoint.timeMatched !== -1 ? moment(areaPoint.timeMatched * 1000).tz(timezone).format('HH:mm:ss') : '-'
            }

            waypointExcel.push(row);

            wpUnchecked = []
            wpChecked = []
            OutTimeWpUnchecked = []
            OutTimeWpChecked = []
        }
    }

    const waypointWorksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(waypointExcel)

    const excelData: ExcelData = {
        worksheet: waypointWorksheet,
        sheetName: 'Waypoints Report',
    }

    return [excelData]
}
