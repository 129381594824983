import React, { memo, useEffect, useMemo } from 'react'
import { groupRanges, rangeToRelative } from './rangeBarUtils'

import './range-bar.css'
import { Popover } from 'antd'
import { finishState } from '../../analyzer/waypointsAnalyzer/waypointsFunctions'

interface Props {
    inactiveStart: number
    theme: string
    ranges: RangeBar[]
    width: number
    state?: string
}

const RangeBar = ({ ranges, inactiveStart, width, theme, state }: Props) => {
    const computedRanges = groupRanges(ranges)
        .map((range: RangeBar) => {
            return rangeToRelative(range, width)
        })
        .map(range => ({
            ...range,
            className: range?.disabled
                ? 'inner-bar inner-bar-inactive'
                : range?.firstUnfinished
                  ? 'inner-bar inner-bar-finish'
                  : range?.matchedGps
                    ? 'inner-bar inner-bar-matchedGps'
                    : 'inner-bar inner-bar-active',
        }))
        
    const endingZone = useMemo(() => {
        console.log(state)
        if (state && (state === finishState.DNF || state === finishState.DNS)) {
            return (
                <div
                    className="inner-bar inner-bar-dns-dnf"
                    style={{ width: `${100 - inactiveStart}%`, marginLeft: `${inactiveStart}%` }}
                ></div>
            )
        }
        return (
            <div
                className="inner-bar inner-bar-end"
                style={{ width: `${100 - inactiveStart}%`, marginLeft: `${inactiveStart}%` }}
            ></div>
        )
    }, [inactiveStart, state])

    return (
        <div className="orderedRangeBar mr-1 self-center">
            <div className={`ranges ranges-theme-${theme}`}>
                {computedRanges.map((range, index) => (
                    <>
                        <div
                            key={index}
                            className={range.className}
                            style={{ width: `${range.width}%`, marginLeft: `${range.start}%` }}
                        ></div>
                    </>
                ))}
                <div>{endingZone}</div>
            </div>
        </div>
    )
}

export default RangeBar
