import MatchTrackHandler from '../matchTrackAnalyzer/matchTrackHandler'
import Analyzer from '../analyzer'
import { WorkerPool } from '../workerPool'
import OppositeHandler from './oppositeHandler'
import { handlerFiltersPutBrand } from '../../utils'

export default class OppositeAnalyzer extends Analyzer {
    resultHandler: OppositeHandler

    oppositeResults: OppositeResult[]

    constructor(rallyId: number, subRallyId : number, section: Section, excluded: any, connections: Devices, routesReports: any) {
        super(rallyId,subRallyId, section, excluded, connections, routesReports)
        this.resultHandler = new OppositeHandler(this, section, excluded)
        this.oppositeResults = []
        this.onResults = () => {}
    }
    public createWorkers(participants: Participant[], maxWorkers: number) {
        super.createWorkers(participants, maxWorkers, 'opposite')
    }
    fetchResults = async (
        participants: Participant[],
        data: DataRallyForm,
        token: string,
        trackCaches: TrackCaches,
        brandFilters: any,
        modeSandBox: boolean
    ) => {
        const specificParams = this._getRequestParamsSpecific(data)
        let brands = null
        if (!modeSandBox) {
            brands = await handlerFiltersPutBrand(
                this.subrallyId,
                this.section.section,
                token,
                data.detectionType,
                specificParams,
                brandFilters
            )
        }
        if (brands) return brands
        for (let participant of participants) {
            const caches = trackCaches[participant.id]
            const params = this._getRequestParams(
                this.rallyId,
                this.subrallyId,
                this.section,
                participant,
                data,
                token,
                caches,
                this.connections
            )

            const worker = await this._pool.get()
            if (worker == null) {
                break
            }

            worker.onmessage = async (e: MessageEvent<MessageAlgorithmOpposite>) => {
                const messageType: string = e.data.type
                switch (messageType) {
                    case 'cacheUpdate':
                        this.updateCache(e)
                        return
                    case 'error':
                        this.updateError(e)
                        return
                    case 'oppositeResult':
                        const generalParamsForReport = e.data.generalParamsForReport
                        const resultParticipant = e.data.participant
                        let resultInfo: OppositeReport = e.data.result
                        this.resultHandler.excludeOutliers(resultInfo, this.excluded)
                        this.resultHandler.setUnmatchedTrackRanges(resultInfo)
                        const areaPoints: AreaPoints[] = this.resultHandler.getAreaPoints(resultInfo)

                        const customTracks: number[][] = this.resultHandler.getLines(resultInfo)
                        const disconnectedGpsTrack = generalParamsForReport.disconnectedGpsTrack
                        const result: OppositeResult = {
                            highlightedPoints: [{}, {}],
                            detectionType: data.detectionType,
                            id: resultParticipant.id,
                            number: resultParticipant.numero,
                            rally: this.subrallyId,
                            data: resultInfo,
                            sectionId: this.section.section,
                            token: token,
                            participantTracks: generalParamsForReport.participantTracks || [],
                            connections: generalParamsForReport.connections,
                            areaPoints: areaPoints,
                            customTracks: customTracks,
                            disconnectedGpsTrack: disconnectedGpsTrack,
                        }
                        this.oppositeResults = [...this.oppositeResults, result]
                        this.onResults(this.oppositeResults)
                        break
                    default:
                        return
                }
                this._pool.put(worker)
            }
            worker.postMessage(['oppositeProcess', params])
        }

        while (this._pool.activeWorkers.length > 0) {
            await new Promise(r => setTimeout(r, 0))
        }

        this._pool.free()

        console.timeEnd('analysis')
    }

    getResults = () => this.oppositeResults

    _getRequestParamsSpecific = (data: DataRallyForm) => {
        return {
            threshold: { opposite: data.detectionTypeData.threshold.opposite },
            minDistanceM: data.detectionTypeData.minDistance,
            toleranceDeg: data.detectionTypeData.tolerance,
        }
    }
    _getRequestParams = (
        rallyId: number,
        subrallyId : number,
        section: Section,
        participant: Participant,
        data: DataRallyForm,
        token: string,
        caches: TrackCache,
        connections: Devices
    ): ParamsAnalyzerOpposite => {
        const reqData: ParamsAnalyzerOpposite = {
            token: token,
            subrallyId: subrallyId,
            rallyId : rallyId,
            section: section,
            from: data.from,
            to: data.to,
            participantTrackType: data.participantTrackType,
            participantUINumber: participant.numero,
            detectionType: data.detectionType,
            caches: caches,
            connections,
            threshold: data.detectionTypeData.threshold.opposite,
            participant: participant,
            minDistanceM: data.detectionTypeData.minDistance,
            toleranceDeg: data.detectionTypeData.tolerance,
        }
        return reqData
    }
}
