import { handlerFiltersPutBrand } from '../../utils'
import Analyzer from '../analyzer'

export default class StatisticsAnalyzer extends Analyzer {
    statisticsResult: StatisticsResult[]

    constructor(rallyId: number, subrallyId : number, section: Section, excluded: any, connections: Devices, routesReports: any) {
        super(rallyId,subrallyId, section, excluded, connections, routesReports)
        this.statisticsResult = []
    }

    public createWorkers(participants: Participant[], maxWorkers: number) {
        super.createWorkers(participants, maxWorkers, 'statistics')
    }
    fetchResults = async (
        participants: Participant[],
        data: DataRallyForm,
        token: string,
        trackCaches: TrackCaches,
        brandFilters: any,
        modeSandBox: boolean
    ) => {
       
        for (let participant of participants) {
            const caches = trackCaches[participant.id]
            const params = this._getRequestParams(
                this.rallyId,
                this.subrallyId,
                this.section,
                participant,
                data,
                token,
                caches,
                this.connections,
            )
            params.modeSandbox = modeSandBox
            const worker = await this._pool.get()
            if (worker == null) {
                break
            }

            worker.onmessage = async (e: MessageEvent<MessageAlgorithmStatistics>) => {
                const messageType: string = e.data.type
                switch (messageType) {
                    case 'cacheUpdate':
                        this.updateCache(e)
                        return
                    case 'error':
                        this.updateError(e)
                        return
                    case 'statisticsResult':
                        const generalParamsForReport = e.data.generalParamsForReport
                        const resultParticipant = e.data.participant
                        let resultInfo: StatisticsReport = e.data.result
                        const disconnectedGpsTrack = generalParamsForReport.disconnectedGpsTrack
                        const result: StatisticsResult = {
                            highlightedPoints: resultInfo.highlightedPoints,
                            detectionType: data.detectionType,
                            id: resultParticipant.id,
                            number: resultParticipant.numero,
                            data: resultInfo,
                            sectionId: this.section.section,
                            rally: this.subrallyId,
                            token: token,
                            participantTracks: generalParamsForReport.participantTracks || [],
                            connections: generalParamsForReport.connections,
                            areaPoints: resultInfo.areaPoints,
                            customTracks: [],
                            disconnectedGpsTrack: disconnectedGpsTrack,
                        }
                        this.statisticsResult = [...this.statisticsResult, result]
                        this.onResults(this.statisticsResult)
                        break
                    default:
                        return
                }
                this._pool.put(worker)
            }
            worker.postMessage(['statisticsProcess', params])
        }

        while (this._pool.activeWorkers.length > 0) {
            await new Promise(r => setTimeout(r, 0))
        }

        this._pool.free()

        console.timeEnd('analysis')
    }

    getResults = () => this.statisticsResult

    _getRequestParams = (
        rallyId: number,
        subrallyId: number,
        section: Section,
        participant: Participant,
        data: DataRallyForm,
        token: string,
        caches: TrackCache,
        connections: Devices,
    ): ParamsAnalyzerStatistics => {
        const reqData: ParamsAnalyzerStatistics = {
            token: token,
            subrallyId: subrallyId,
            rallyId : rallyId,
            section: section,
            from: data.from,
            to: data.to,
            participantTrackType: data.participantTrackType,
            participantUINumber: participant.numero,
            detectionType: data.detectionType,
            caches: caches,
            connections,
            threshold: data.detectionTypeData.threshold,
            participant: participant,
        }

        return reqData
    }
}
