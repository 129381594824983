import React, { useState } from 'react'
import ParticipantCard from '../participantCard'
import RangeBar from '../../RangeBar/rangeBar'

import 'moment-timezone'
import './waypoints-card.css'
import DnsDnf from './DnsDnf'
import PositionsLost from '../../PositionsLost'
import { calculateLostPositions } from '../../../utils'
import WaypointsFailed from './WaypointsFailed'
import { FaMapMarkerAlt } from 'react-icons/fa'
import {
    getASSWaypoint,
    getDSSWaypoint,
    putWaypointCheck,
} from '../../../analyzer/waypointsAnalyzer/waypointsFunctions'
//
interface Props {
    className: string
    onClick: any
    result: any
    openPopup: any
    rallyId: number
    token: string
}
const WaypointsCard = ({ className, onClick, result, openPopup, rallyId, token }: Props) => {
    const [state, setState] = useState<string>(result.data.valueDnsDnf)
    let lastWaypoint: number = 0
    let inactiveStartsAt: number
    let mainPositions = calculateLostPositions(result.disconnectedGpsTrack.main)
    let secondaryPositions = calculateLostPositions(result.disconnectedGpsTrack.secondary)
    let findedWpId: number | undefined
    if (result.data.waypointFinded) {
        findedWpId = result.data.waypointFinded[0].waypointFinded?.wpId
    }
    const wpDSS = getDSSWaypoint(result.data.waypoints)
    const wpASS = getASSWaypoint(result.data.waypoints)
    const unmatchedWaypoints: RangeBar[] = result.data.waypoints.map(
        (waypoint: Waypoint, i: number, waypoints: any) => {
            return {
                edges: [(i / waypoints.length) * 100, ((i + 1) / waypoints.length) * 100],
                disabled: waypoint.excluded || false,
                matched: waypoint.matched,
                matchedGps: waypoint.matchedGps,
                blocked: Boolean(waypoint.blocked),
            }
        }
    )
    const unmatchedWaypointsFiltered = unmatchedWaypoints.filter(e => !e.matched)
    for (let i = (result.data as WaypointsReport).waypoints.length - 1; i >= 0; i--) {
        if (
            (result.data as WaypointsReport).waypoints[i].matched ||
            (result.data as WaypointsReport).waypoints[i].matchedGps
        ) {
            lastWaypoint = i + 1
            break
        }
    }
    inactiveStartsAt = (lastWaypoint! / (result.data as WaypointsReport).waypoints.length) * 100 || 0
    if (!unmatchedWaypoints.length) {
        return <></>
    }
    const [waypointsFailed, setWaypointsFailed] = useState<Waypoint[]>(
        result.data.waypoints.filter(
            (e: Waypoint, index: number) => !e.matched && !e.matchedGps && index < lastWaypoint
        )
    )
    const removeWaypoint = (waypointId: number) => {
        const updatedWaypoints = waypointsFailed.filter(wp => wp.id !== waypointId)
        setWaypointsFailed(updatedWaypoints)
    }

    let waypointsFailedList: any = <></>
    if (waypointsFailed.length > 0) {
        waypointsFailedList = waypointsFailed.map((waypoint, index) => {
            return (
                <div
                    className={`m-1 flex flex-row justify-center text-nowrap rounded text-right`}
                    onClick={() => openPopup(waypoint.name)}
                    key={index}
                >
                    <div
                        className="flex flex-row space-x-1 
                            self-center rounded "
                    >
                        <div className=" italic underline">Waypoint Failed:</div>
                        <div className="font-bold hover:cursor-pointer">{waypoint.name}</div>
                        <a
                            className=""
                            target="_blank"
                            rel="noopener noreferrer"
                            href={waypoint.adminLink}
                            title="Waypoint Link"
                        >
                            Waypoint link
                        </a>
                    </div>
                    <div
                        className="ml-2"
                        onClick={event => {
                            event.stopPropagation()
                            putWaypointCheck(waypoint, rallyId, token, result.id, 1)
                                .then(value => {
                                    removeWaypoint(waypoint.id)
                                })
                                .catch(error => {
                                    console.error('Error:', error)
                                })
                        }}
                    >
                        <FaMapMarkerAlt className="text-[green] hover:cursor-pointer" />
                    </div>
                </div>
            )
        })
    }
    let collapseData = <></>
    if (waypointsFailed.length > 0) {
        collapseData = (
            <div className="flex flex-col rounded border-[1px] border-solid border-neutral-400 bg-neutral-300">
                <div className="text-center text-xl">Waypoints Failed</div>
                <div className="divide-x bg-neutral-200">{waypointsFailedList}</div>
            </div>
        )
    }
    return (
        <ParticipantCard
            type="Waypoints"
            number={result.number}
            rallyId={result.rally}
            onClick={() => onClick(result)}
            className={`${className} waypoint-card m-1`}
            collapseData={collapseData}
        >
            <RangeBar
                ranges={unmatchedWaypointsFiltered}
                inactiveStart={inactiveStartsAt}
                width={100}
                state={state}
                theme="match-track"
            />
            <WaypointsFailed waypointsFailedCount={waypointsFailed.length}></WaypointsFailed>
            <PositionsLost positions={mainPositions + secondaryPositions} />
            <DnsDnf dnsDnf={state} wpASS={wpASS} wpDSS={wpDSS} updateState={setState} participantId={result.id} subrallyId={rallyId} token={token}/>
        </ParticipantCard>
    )
}

export default WaypointsCard
